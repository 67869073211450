import classNames from 'classnames';
import { PaginationFooter } from 'components/common/PaginationFooter';
import { MainLayout } from 'components/layouts/MainLayout';
import { ModalMode } from 'components/layouts/ModalLayout';
import { CardInfoModal } from 'components/modals/CardInfoModal';
import { CreateTabModal } from 'components/modals/CreateTabModal';
import { Modal } from 'components/modals/Modal';
import { TabFiltersPopUp } from 'components/modals/TabFiltersPopUp';
import { CardsTable } from 'components/tables/CardsTable';
import useTabManagement from 'lib/hooks/useTabManagement';
import { HiAdjustments, HiTable } from 'react-icons/hi';

export const TabManagementPage = () => {
  const {
    currentSortingParams,
    isCreateTabModalVisible,
    isDetailVisible,
    isFilterDropdownVisible,
    activeFilters,
    currentPaginationParams,
    selectedTabData,
    updateCurrentFilters,
    openModalAndSetId,
    setSelectedTabData,
    setIsDetailsVisible,
    getCardsRequest,
    setIsFilterDropdownVisible,
    closeDetailModal,
    closeCreateTabModal,
    onOpenCreateTabModal,
    onSortClick,
    onCardUpdate,
    onCardDelete,
    onExportButtonClick,
    onApprove,
  } = useTabManagement(false);

  return (
    <MainLayout>
      <div className="flex flex-col">
        <div className="flex items-center justify-between pt-5 pb-4 px-4 border-b border-gray-200">
          <p className="text-gray-900 text-xl text-left"> Tab Management</p>
          <div className="flex text-gray-500 relative">
            <button
              className="border border-gray-100 rounded py-2 px-4 flex items-center text-sm mr-4"
              onClick={onOpenCreateTabModal}
            >
              <HiTable className="fill-gray-500 mx-1" />
              Add Tab
            </button>
            <button
              className="border border-gray-100 rounded py-2 px-4 flex items-center test-sm mr-4"
              onClick={onExportButtonClick}
            >
              <HiTable className="fill-gray-500 mx-1" />
              Download in Excel
            </button>
            <button
              className={classNames(
                'border border-gray-100 rounded p-2 flex items-center text-sm',
                {
                  'bg-gray-100 text-black':
                    isFilterDropdownVisible || activeFilters > 0,
                },
              )}
              onClick={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
            >
              <HiAdjustments
                className={classNames('mx-1', {
                  'fill-gray-500': activeFilters === 0,
                  'fill-black text-black font-bold':
                    isFilterDropdownVisible || activeFilters > 0,
                })}
              />
              Filters {activeFilters ? `(${activeFilters})` : ''}
            </button>
            <TabFiltersPopUp
              isVisible={isFilterDropdownVisible}
              onClose={() =>
                setIsFilterDropdownVisible(!isFilterDropdownVisible)
              }
              onChangeFilters={updateCurrentFilters}
            />
          </div>
        </div>
        <CardsTable
          onItemClick={openModalAndSetId}
          onSortClick={onSortClick}
          currentSorting={currentSortingParams}
        />
        <PaginationFooter
          itemsPerPage={currentPaginationParams.itemsPerPage}
          currentPage={currentPaginationParams.page}
          totalItems={currentPaginationParams.totalItems}
          onPageClick={(newPage) => getCardsRequest(newPage)}
        />
      </div>
      <Modal mode={ModalMode.right} isModalVisible={isDetailVisible}>
        <CardInfoModal
          cardData={selectedTabData}
          closeModal={closeDetailModal}
          onDelete={onCardDelete}
          onApprove={onApprove}
          onUpdate={onCardUpdate}
          isDraft={false}
        />
      </Modal>
      <Modal mode={ModalMode.center} isModalVisible={isCreateTabModalVisible}>
        <CreateTabModal
          closeModal={closeCreateTabModal}
          onCreate={(cardId: string, isMediaTypeAudioText: boolean) => {
            setSelectedTabData({
              id: cardId,
              isCreatingFlow: isMediaTypeAudioText,
            });
            if (isMediaTypeAudioText) {
              setIsDetailsVisible(true);
            }
            getCardsRequest();
          }}
        />
      </Modal>
    </MainLayout>
  );
};
