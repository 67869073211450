import { TabFilters, TableSorting } from 'lib/types/entities';
import api, { PagingParams } from './api';
import { MediaFile } from './mediaFilesServices';

export type GetCardsItem = {
  '@id': string;
  '@type': string;
  sequenceNumber: number;
  title: string;
  type: string;
  active: boolean;
  advanced: boolean;
  frequency: number;
  responseLimitNum: number;
  responseLimitType: string;
  mediaType: string;
  responseType: string;
  similiatityGroup: number;
  files: MediaFile[];
  draft?: boolean;
  reference?: string | null;
  craftSentence?:string | null;
  craftExpressions?: CraftExpression[];
};

export type CraftExpression = {
  expressionId: string;
  type: string;
  required: boolean,
  value: string,
  prompt: string;
}

export type PostCardRequestData = {
  title: string;
  type: string;
  mediaType: string;
  responseType: string;
  frequency: number;
  active: boolean;
  advanced: boolean;
  similiatityGroup: number;
  responseLimitType: string | null;
  responseLimitNum: number | null;
  promptTitle: string;
  subtextRegular: string;
  promptFinishTab: string;
  subtextStarter: string;
  createdAt?: string;
  draft?: boolean;
  reference?: string | null;
  craftSentence?:string | null;
  craftExpressions?: CraftExpression[] | null;
  used?: boolean;
};

export type PatchCardRequestData = Partial<
  Omit<PostCardRequestData, 'files'> & {
    files: CardFilesEdited[];
  }
>;
export type PostDraftCardRequestData = Partial<
  Omit<PostCardRequestData, 'files'> & {
    files: CardFilesEdited[];
  }
>;
export type CardFilesEdited = {
  file: string;
  title: string;
  sorting: number;
};
export type CardFile = {
  '@id': string;
  file: MediaFile;
  title: string;
  sorting: number;
};

export type GetCardByIdResponse = PostCardRequestData & {
  '@context'?: string;
  '@id'?: string;
  '@type'?: string;
  sequenceNumber: number;
  createdAt: string;
  updatedAt: string;
  used: boolean;
  files?: CardFile[];
};

export type GetCardsResponse = {
  'hydra:member': GetCardsItem[];
  'hydra:totalItems': number;
};
export type GetCardsRequestParams = {
  params: PagingParams & {
    order?: TableSorting;
  } & TabFilters;
};

export const cardsService = () => {
  const getCards = async ({
    params = { page: 1, itemsPerPage: 30, order: { id: 'desc' } },
  }: GetCardsRequestParams) => {
    return api({}).get<GetCardsResponse>('/cards', { params });
  };

  const postCard = async ({ data }: { data: PostCardRequestData }) => {
    return api({ headers: { 'Content-Type': 'application/ld+json' } }).post(
      '/cards',
      data,
    );
  };

  const getCardById = async (cardId: string) => {
    return api({}).get(`/cards/${cardId}`);
  };

  const patchCardById = async (cardId: string, data: PatchCardRequestData) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/cards/${cardId}`, data);
  };

  const deleteCardById = async (cardId: string) => {
    return api({}).delete(`/cards/${cardId}`);
  };

  const patchCardFilesSorting = async (
    cardId: string,
    fileId: string,
    data: {
      direction: 'up' | 'down';
      step: number;
    },
  ) => {
    return api({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch(`/cards/${cardId}/card_files/${fileId}/sorting`, data);
  };

  const getCardsExportExcel = ({ params }: GetCardsRequestParams) => {
    return api({}).get('/cards/export/excel', {
      params,
      responseType: 'blob',
    });
  };

  const postCardsApprove = (cardDraftIds: string[] | undefined) => {
    return api({ headers: { 'Content-Type': 'application/ld+json' } }).post(
      '/cards/drafts_approve',
      { cardDraftIds: cardDraftIds || undefined },
    );
  };
  const postCardsAsDraft = (cardData: PostDraftCardRequestData) => {
    return api({ headers: { 'Content-Type': 'application/ld+json' } }).post(
      '/cards/save_as_draft',
      cardData,
    );
  };
  return {
    deleteCardById,
    patchCardById,
    getCardById,
    postCard,
    getCards,
    patchCardFilesSorting,
    getCardsExportExcel,
    postCardsAsDraft,
    postCardsApprove,
  };
};
