import api from './api';
import { DAYS_OF_WEEK } from 'lib/enums';

export type PushConfigDay = {
  type: string;
  dayTitle: string;
  dayMessage: string;
  specificDay: boolean;
  deliveryTime: string;
  active: boolean;
  days: ReadonlyArray<{
    id: string;
    day: DAYS_OF_WEEK;
    specificDayTitle: string;
    specificDayMessage: string;
    specificDay: boolean;
    deliveryTime: string;
    active: boolean;
  }>;
};

export type PushConfigDayCompare = {
  type: string;
  draftActive: boolean | null;
  draftDayMessage: string;
  draftDayTitle: string;
  draftDeliveryTime: string;
  draftSpecificDay: boolean | null;
  liveActive: boolean | null;
  liveDayMessage: string;
  liveDayTitle: string;
  liveDeliveryTime: string;
  liveSpecificDay: boolean | null;
  days: ReadonlyArray<{
    id: string;
    day: DAYS_OF_WEEK;
    draft: {
      id: string;
      day: DAYS_OF_WEEK;
      specificDayTitle: string;
      specificDayMessage: string;
      specificDay: boolean;
      deliveryTime: string;
      active: boolean;
    };
    live: {
      id: string;
      day: DAYS_OF_WEEK;
      specificDayTitle: string;
      specificDayMessage: string;
      specificDay: boolean;
      deliveryTime: string;
      active: boolean;
    };
  }>;
};

export type GetPushConfigDraftResponse = {
  'hydra:member': ReadonlyArray<PushConfigDay>;
  'hydra:totalItems': number;
};

export type GetPushConfigDraftCompareResponse = {
  'hydra:member': ReadonlyArray<PushConfigDayCompare>;
  'hydra:totalItems': number;
};

export const pushConfigDraftsService = () => {
  const getPushConfigDraftsRequest = async () => {
    return api({}).get<GetPushConfigDraftResponse>(
      `/push_notification_setting_drafts`,
      {},
    );
  };

  const getPushConfigDraftsCompareRequest = async () => {
    return api({}).get<GetPushConfigDraftCompareResponse>(
      `/push_notification_setting_drafts/compare/live`,
      {},
    );
  };

  const patchPushConfigDraftsRequest = async (configId: string, data: any) => {
    return api({
      headers: { 'Content-type': 'application/merge-patch+json' },
    }).patch<GetPushConfigDraftResponse>(
      `/push_notification_setting_drafts/${configId}`,
      data,
    );
  };

  const patchPushConfigDraftsRequestBatch = async (data: any) => {
    return api({
      headers: { 'Content-type': 'application/ld+json' },
    }).post<GetPushConfigDraftResponse>(
      `/push_notification_setting_drafts/batch`,
      data,
    );
  };

  const approvePushConfigDraftsRequest = async () => {
    return api({ headers: {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json'
    } }).post(
      `/push_notification_setting_drafts/approve`,
      {}
    );
  };

  const resetPushConfigDraftsRequest = async () => {
    return api({ headers: {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json'
    } }).post(
      `/push_notification_setting_drafts/reset`,
      {}
    );
  };

  return {
    getPushConfigDraftsRequest,
    getPushConfigDraftsCompareRequest,
    patchPushConfigDraftsRequest,
    patchPushConfigDraftsRequestBatch,
    approvePushConfigDraftsRequest,
    resetPushConfigDraftsRequest,
  };
};
