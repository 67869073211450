import { useForm } from 'react-hook-form';
import { showToast } from 'lib/toast';
import { PostCardRequestData, cardsService } from 'lib/services/cardsServices';
import { CardForm } from 'components/pages/TabManagementPage/CardForm';
import { baseBackendErrorHandler } from 'components/common/ErrorHandler/ErrorHandler';
import { BackendError, CardMediaTypes } from 'lib/types/entities';
import { FC } from 'react';

export type CreateTabModalProps = {
  readonly closeModal: () => void;
  readonly onCreate: (
    card: string,
    isFilesRedirect: boolean,
    isDraft?: boolean,
  ) => void;
};

const { postCard, postCardsAsDraft } = cardsService();

export const CreateTabModal: FC<CreateTabModalProps> = ({
  closeModal,
  onCreate,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<PostCardRequestData>({
    mode: 'onChange',
    defaultValues: {
      mediaType: 'text',
      responseType: 'text',
      frequency: 1,
      similiatityGroup: 1,
      type: 'mindfulness',
    },
  });

  const isMediaTypeAudioText = watch('mediaType') === CardMediaTypes.audio_text;

  const onDraftSubmit = async (form: PostCardRequestData) => {
    try {
      await postCardsAsDraft({
        ...form,
        title: form.title.trim(),
        active: Boolean(form.active),
        mediaType: form.mediaType,
        promptTitle: form.promptTitle.trim(),
        subtextRegular: form.subtextRegular.trim(),
        promptFinishTab: form.promptFinishTab.trim(),
        subtextStarter: form.subtextStarter.trim(),
        responseLimitNum:
          form.responseType === 'text' ? Number(form?.responseLimitNum) : null,
        responseLimitType:
          form.responseType === 'text' ? form.responseLimitType : null,
        frequency: Number(form.frequency),
        similiatityGroup: Number(form.similiatityGroup),
        reference: null,
      });
      closeModal();
      showToast({
        type: 'success',
        title: 'Draft Card has been successfully created!',
      });
    } catch (e) {
      const errorResponse = e as {
        data?: BackendError;
        status: number;
      };
      if (
        errorResponse.data?.violations &&
        (errorResponse.status === 422 || errorResponse.status === 400)
      ) {
        baseBackendErrorHandler(errorResponse.data?.violations, setError);
        return;
      }
      showToast({
        type: 'error',
        title: 'Something went wrong with draft card creation!',
        message: 'Try again later.',
      });
    }
  };

  const onSubmit = async (form: PostCardRequestData) => {
    try {
      const postResponse = await postCard({
        data: {
          ...form,
          title: form.title.trim(),
          active: Boolean(form.active),
          promptTitle: form.promptTitle.trim(),
          subtextRegular: form.subtextRegular.trim(),
          promptFinishTab: form.promptFinishTab.trim(),
          subtextStarter: form.subtextStarter.trim(),
          responseLimitNum:
            form.responseType === 'text'
              ? Number(form?.responseLimitNum)
              : null,
          responseLimitType:
            form.responseType === 'text' ? form.responseLimitType : null,
          frequency: Number(form.frequency),
          similiatityGroup: Number(form.similiatityGroup),
        },
      });
      closeModal();
      showToast({
        type: 'success',
        title: 'Card has been successfully created!',
        message: 'Card has been created successfully!',
      });
      onCreate(
        postResponse.data['@id'].replace('/cards/', ''),
        isMediaTypeAudioText,
      );
    } catch (e) {
      const errorResponse = e as {
        data?: BackendError;
        status: number;
      };
      if (
        errorResponse.data?.violations &&
        (errorResponse.status === 422 || errorResponse.status === 400)
      ) {
        baseBackendErrorHandler(errorResponse.data?.violations, setError);
        return;
      }
      showToast({
        type: 'error',
        title: 'Something went wrong with card creation!',
        message: 'Try again later.',
      });
    }
  };

  return (
    <div className="flex flex-col p-10 rounded w-[100%] min-w-[700px]">
      <p className="text-2xl font-semibold pb-6">Add Tab</p>
      <CardForm
        control={control}
        watch={watch}
        register={register}
        formState={formState}
        updateValue={(fieldName, value) => setValue(fieldName, value)}
        clearErrors={clearErrors}
        isCreating
      />
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="text-blue-500 bg-white px-4 py-2 rounded mr-2"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-blue-500 text-white px-10 py-2 rounded mx-4"
          onClick={handleSubmit(onDraftSubmit)}
        >
          Create as draft
        </button>
        <button
          type="button"
          className="bg-blue-500 text-white px-10 py-2 rounded"
          onClick={handleSubmit(onSubmit)}
        >
          {isMediaTypeAudioText ? 'Continue' : 'Create'}
        </button>
      </div>
    </div>
  );
};
